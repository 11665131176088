import React from 'react'



function Hero(props) {
  const { image, desc, linkurl } = props;
  return (
    <div>
      <div className='' style={{"fontFamily":"Inclusive Sans"}}>
        <div className="hidden lg:block lg:w-[15rem] lg:h-[30rem] xl:w-80 xl:h-[30rem]  absolute top-40 left-24 z-10  bg-opacity-50 backdrop-blur-md lg:p-2 xl:p-4 rounded-lg text-white bg-red-500" style={{ "box-shadow": "inset 5px 5px 8px #9e1e1e, inset -5px -5px 8px #ff3232" }}>
          <div className='flex flex-col justify-center'>

            <div>
              <div className='lg:w-56 lg:h-48 xl:w-72 xl:h-60  '>
                <img className=' ' src={image} alt="" />
                <hr className='mt-3 lg:w-44 xl:w-full m-auto' />
              </div>
              <div className='-mt-5 text-justify'>
                <p className='text-xs'>{desc}</p>
              </div>
              
            </div>
            {linkurl && <div className="hidden lg:-ml-2 xl:-ml-4 lg:block lg:x-40 xl:w-80 h-[3rem] bg-red-500 z-10 my-10   bg-opacity-50 backdrop-blur-md p-3 rounded-lg " style={{ "box-shadow": "inset 5px 5px 8px #9e1e1e, inset -5px -5px 8px #ff3232" }}>
                <span className='mx-2 text-white'>link:</span><a href={linkurl} target='_blank' className='text-blue-400' >Project video</a>
            </div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
