import React from 'react'
import photo from '../image/avatar.png'
function ProfileSide(props) {
  // "box-shadow":  "5px 5px 8px #9e1e1e, -5px -5px 8px #ff3232"
  return (
    <div className='px-5 py-2 m-4 rounded-md  bg-red-500' style={{ "box-shadow":  "-3px -3px 10px #a62323, 3px 3px 5px #ff4242" }} >
      <div className='rounded-full flex '>
        <img className='rounded-full w-14 object-scale-down ' src={props.photo} alt="" />
        {props.heading && <p className='ml-3 py-3 font-semibold text-xl text-white' style={{"fontFamily":"Inclusive Sans"}}>{props.heading}</p>}
        {!props.heading && <p className='ml-3 py-3 font-semibold'>Heading</p>}
      </div>
      <div className='w-80 py-3'>
        {props.description && <p className='text-white  ' style={{"fontFamily":"Inclusive Sans"}}>{props.description} </p>}
        {!props.description && <p className='pb-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio nihil voluptatum ab sequi assumenda nobis, perferendis accusamus voluptates nesciunt enim, dolor cum laboriosam recusandae? Consequatur ipsa animi pariatur non culpa? </p>}
      </div>
    </div>
  )
}

export default ProfileSide
