import React from 'react'
import dog from '../image/dog2.gif'
function Interact() {
  return (
    <div>
      <div>
        <img src={dog} alt="" />
        
      </div>
    </div>
  )
}

export default Interact
