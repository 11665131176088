import React from 'react'




function skills(props) {
    const { image, name, description, skills, link, live } = props;
    return (
        <div className='border-2 rounded-lg sm:w-80'>
            <div className='h-48 p-2'>
                <img className='w-full h-full object-cover' src={image} alt="" />
            </div>
            <div className='p-2 mb-3'>
                <p className='text-xl font-bold'>{name}</p>
                <p className='text-sm'>{description}</p>
            </div>
            <div className='p-2 border-y-2 '>
                <p className='font-bold'>SKILLS USED:</p>

                <div className='p-2 flex flex-row flex-wrap gap-1 mx-auto'>
                    {skills.map((skill) => (
                        <p className='bg-blue-300 inline-block p-1 rounded-lg text-sm px-4 text-center font-bold'>
                            {skill}
                        </p>
                    ))}
                </div>

            </div>
            <div className='flex flex-row flex-wrap gap-2 p-2'>
                {live && (
                    <div className='p-1 bg-blue-600 text-center text-white w-24 h-8 rounded-lg font-bold' >
                        <p>See Live</p>
                    </div>)}
                {link && (
                    <a href='https://github.com/Tissu07/Safe-inDrive-incode' >
                        <div className='p-1 bg-blue-600 text-center text-white w-24 h-8 rounded-lg font-bold' >
                            <p>See Repo</p>
                        </div></a>)
                }
            </div>
        </div>
    )
}

export default skills
