import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import photo from '../image/avatar.png'
import rain from '../image/rainny.jpeg'
import photo2 from '../image/car.jpg'
import "../App.css"
import Download from './Download'
import Skills from './Skills'
import devops from '../image/devops.png'
import docker from '../image/docker.png'
import jenkins from '../image/jenkins.png'

import side1 from '../image/7SideProfile.jpg'
import side2 from '../image/1SideProfile.jpg'
import side3 from '../image/6SideProfile.jpg'
import side4 from '../image/5SideProfile.jpg'
import side5 from '../image/2SideProfile.jpg'
import side6 from '../image/4SideProfile.jpg'
import side7 from '../image/8SideProfile.jpg'
import side8 from '../image/10SideProfile.jpg'
import side9 from '../image/3SideProfile.jpg'
import firstImageAbout from '../image/indrivenew.jpg'
import indrivenew from '../image/indrivenew.jpg'
import hackathon from '../image/Hackathon.png'
import fullstack from '../image/full.png'
import github from '../image/github.png'
import aws from '../image/aws.png'
import ecommerce from '../image/ecommerce.png'
import indrive from '../image/indrive.png'
import login from '../image/project2.png'




import { motion, AnimatePresence, easeIn } from "framer-motion"
import ProfileSide from './ProfileSide';
import Hero from './Hero';
import Resume from './Resume';
import Projects from './Projects';
import Interact from './Interact'
import Project2 from './Project2'
import Work from './Work'









function Home() {

  const [showAbout, setshowAbout] = useState(false);
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [project, setProject] = useState(false);
  const [blog, setBlog] = useState(false);
  const [resume, setResume] = useState(false);
  const [work, setWork] = useState(false);

  const [hoverElement, setHoverElements] = useState('');


  const handleAbout = () => {
    if (showAbout) {
      setshowAbout(false);
      setOne(false);
      setTwo(false)
      setThree(false)
    }
    else {
      setshowAbout(true);
      setOne(true);
      setTwo(false)
      setThree(false)
    }
  }

  const handleOne = () => {
    setOne(true)
    setTwo(false)
    setThree(false)
    console.log(one)
  }
  const handleTwo = () => {
    setOne(false)
    setTwo(true)
    setThree(false)
    console.log(two)
  }
  const handleThree = () => {
    setOne(false)
    setTwo(false)
    setThree(true)
    console.log(three)
  }



  const handleProject = () => {
    if (project) {
      setProject(false)
    }
    else {
      setProject(true)
      setResume(false)
      setBlog(false)
      setWork(false)
    }
  }

  const handleBlog = () => {
    if (blog) {
      setBlog(false)
    }
    else {
      setBlog(true)
      setResume(false)
      setProject(false)
      setWork(false)

    }
  }

  const handleWork = () => {
    if (work) {
      setWork(false)
    }
    else {
      setWork(true)
      setResume(false)
      setProject(false)
      setBlog(false)
    }
  }

  const handleHome = () => {
    setWork(false)
    setResume(false)
    setProject(false)
    setBlog(false)
  }


  const handleResume = () => {
    if (resume) {
      setResume(false)
    }
    else {
      setResume(true)
      setProject(false)
      setBlog(false)
      setWork(false)
    }
  }

  const handleMaster = () => {
    setProject(false)
    setWork(false)
    setBlog(false)
  }

  const spring = {
    type: "spring",
    damping: 20,
    stiffness: 100,
    duration: 80
  }

  const handleHover = (e) => {
    console.log(e)
  }

  const handleProjectBack = () => {
    console.log("false")
  }

  const projects = [
    {
      "id": 1,
      "image": ecommerce,
      "name": "E-commerce",
      "description": "Discover a seamless online shopping experience with our MERN-based e-commerce platform. We've meticulously crafted every detail, from an intuitive user interface to a robust backend, ensuring a secure and efficient shopping journey.",
      "skills": ["HTML", "CSS", "REACT", "TAILWIND", "NODE", "EXPRESS", "MONGODB"]
    },
    {
      "id": 2,
      "image": indrive,
      "name": "Indrive",
      "description": "Revolutionizing road safety with 'Safety in Indrive,' our hackathon project advances to the prototype round. Focused on real-time hazard detection and driver assistance, we're pioneering in-vehicle safety for a secure driving experience. ",
      "skills": ["HTML", "CSS", "REACT", "TAILWIND", "NODE", "EXPRESS", "TWILLIO"],
      "link": "https://github.com/Tissu07/Safe-inDrive-incode"
    },
    {
      "id": 3,
      "image": login,
      "name": "Login",
      "description": "Step into a sleek digital realm with our cutting-edge login experience. Our Windows-inspired interface, crafted with finesse using Tailwind CSS in React, merges style with seamless functionality that advance the login process.",
      "skills": ["HTML", "CSS", "REACT", "TAILWIND"]
    }
  ];


  const quote = ["Coding: where problems are puzzles and bugs are challenges. I'm the hero of this digital adventure!", "Debugging is my cardio, and coding is my art. With every line, I paint the future of technology.", "In the world of 0s and 1s, I'm the one who makes things happen. I code, I conquer, I create.", "Programmer by day, coder by night. I turn caffeine into code and dreams into reality.", "Embrace the bugs, for they are your greatest teachers. In code, we learn, we grow, and we thrive."]



  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const [text, setText] = useState('');
  const fullText = `"Coding is not just about commands, functions, and algorithms. It's about expressing your creativity, solving problems, and building something from nothing." - Unknown`; // The full text you want to animate

  useEffect(() => {
    let isMounted = true;
    let currentIndex = 0;

    const typeText = () => {
      if (isMounted && currentIndex < fullText.length) {
        setText(fullText.slice(0, currentIndex + 1));
        currentIndex++;
        setTimeout(typeText, 100); // Adjust the typing speed (milliseconds per character)
      }
    };

    typeText();
    return () => {
      isMounted = false;
    };
  }, []);



  return (
    <>

      <div className='bg-red-500 text-center text-white lg:hidden xl:hidden'>Recommendation: Open in large screen <span className='text-xs'>(here: short review)</span></div>
      <div className=' bg-[#282828] px-10 flex flex-col gap-8 lg:hidden'>

        <div className='sm:flex sm:flex-row gap-6'>

          <div className='pt-10 flex sm:flex-col  text-white gap-7 sm:w-1/4 '>
            <div className=' '>
              <img className='h-24 object-scale-down rounded-2xl sm:m-auto' src={photo} alt="" />
            </div>
            <div className='sm:text-center'>
              <p className='text-xl font-semibold'>Abhishek Pal</p>
              <p className='text-sm my-1'>Full-stack Developer</p>
              <p className='text-xs -ml-2'><i class="fa-solid fa-location-dot fa-sm px-2"></i>New Delhi, INDIA</p>
              <div className='mt-2'>
                <Download name="Resume" />
              </div>
            </div>
          </div>

          <div className='text-white  pt-8 text-justify sm:w-2/3'>
            <p>Experienced Full Stack Developer and Team Lead with a demonstrated history of working in remote environments. Proficient in a range
              of programming languages including C, C++, JavaScript, Python, HTML, CSS, React, and Tailwind.</p>
            <p className='hidden sm:block mt-2'>I am dedicated to staying current with the latest trends and advancements in technology, continuously expanding my skillset to stay ahead in this rapidly evolving industry. I am excited about the opportunity to leverage my expertise and leadership abilities to drive innovation and deliver exceptional results in your organization.</p>
          </div>

        </div>

        <div className='text-white'>
          <p className='mb-8 text-2xl'>Skills</p>
          <div className='flex flex-wrap justify-center'>
            <div className='flex flex-row gap-2 mb-2 sm:mb-4 sm:gap-4 justify-center'>
              <Link to="/devops">
                <img className='object-cover w-32 sm:w-80 h-24 sm:h-40 m-auto' src={fullstack} alt="" />
              </Link>

              <Link to="/devops">
                <img className='object-cover w-32 sm:w-80 h-24 sm:h-40 m-auto' src={github} alt="" />
              </Link>

              <Link to="/skill">
                <img className='object-cover w-32 sm:w-80 h-24 sm:h-40 m-auto' src={aws} alt="" />
              </Link>
            </div>

            <div className='flex flex-row gap-2 justify-center'>
              <Link to="/devops">
                <img className=' object-cover w-32 sm:w-80 h-20 sm:h-40 m-auto' src={devops} alt="" />
              </Link>

              <Link to="/devops">
                <img className=' object-cover w-32 sm:w-80 h-20 sm:h-40 m-auto' src={docker} alt="" />
              </Link>

              <Link to="/skill">
                <img className=' object-cover w-32 sm:w-80 h-20 sm:h-40 m-auto' src={jenkins} alt="" />
              </Link>
            </div>






          </div>
        </div>

        <div className='text-white'>
          <p className='mb-8 text-2xl'>Education</p>
          <ul className="list-disc pl-5 text-lg">
            <li className='py-1'><span className='text-blue-400'>Bachelor of Technology</span> - BTech, Computer Engineering
              Sharda University • Greater Noida • April 2025 • 8.4</li>
            <li className='py-1'><span className='text-blue-400'>Higher Education</span>- 11 & 12, PCM Oxford public school  •Ranchi •June 2021 • 85%</li>
            <li className='py-1'><span className='text-blue-400'>Secondary Education</span>- 10, DAV public school  •Ranchi •Mar 2019 • 90%</li>
          </ul>
        </div>


        <div className='text-white'>
          <p className='mb-8 text-2xl'>Projects</p>
          {/* <div className='flex flex-row gap-3 flex-wrap justify-center'>
            <div className='w-80 inline-b lock   rounded-xl py-6'>
              <img className='h-36 m-auto' src={ecommerce} alt="" />
              <p className='text-xl pt-2 text-center'>Ecommerce</p>
            </div>
            <div className='w-80 inline-b lock   rounded-xl  py-6'>
              <img className='h-36 m-auto' src={indrive} alt="" />
              <p className='text-xl pt-2 text-center'>Safe Indrive</p>
            </div>
            <div className='w-80 inline-b lock   rounded-xl  py-6'>
              <img className='h-36 m-auto' src={login} alt="" />
              <p className='text-xl pt-2 text-center'>Admin Login</p>
            </div>
          </div> */}

          <div className='flex flex-col gap-2 sm:flex-row'>
            {
              projects.map((project) => {
                return <Skills key={project.id} image={project.image} name={project.name} description={project.description} skills={project.skills} link={project.link} live={project.live} />
              })
            }
          </div>

        </div>

        {/* bg-[#D83F87] */}

        <div className='text-white mb-5'>
          <p className='mb-8 text-2xl'>Experience</p>
          <ul className="list-disc pl-5 text-lg">
            <li className='py-1'><span className='text-blue-400'>Full stack developer</span>-
              Hinafi Technology June 2023 - December 2023, Delhi</li>
          </ul>
          <p className='text-lg hidden sm:block'>Work:</p>
          <p className='text-justify mt-2  hidden sm:block'>• Developed and maintained a medical counseling website, ensuring a user-friendly and secure platform for patients seeking guidance.
            • Collaborated closely with UI/UX designers to spearhead the implementation of highly responsive front-end interfaces using React,
            resulting in a 40% improvement in overall user experience and a 20% increase in customer satisfaction.
            • Implemented real-time communication features, enabling seamless interaction between patients and counselors through dynamic
            chat functionalities with 100% accuracy.</p>
        </div>

        <div className='text-white mb-4'>
          <p><i class="fa-solid fa-quote-left"></i></p>
          <p className='text-justify'>Hello there! Thank you so much for dropping by and checking out my portfolio. Your visit means a lot, and I'm genuinely looking forward to the possibility of working together. Let's create something amazing! Feel free to reach out if you have any questions or ideas to share. Wishing you a fantastic day ahead!</p>

        </div>

      </div>

      {/* *************************************************************small************************************************************************************************** */}

      <div className='hidden lg:block  bg-red-500 '>

        {/* bg-[#282828] */}
        {!(work || project || resume) && <div
          className='py-3 px-10 rounded-md bg-red-500 inline-block absolute right-10 top-10 z-30 cursor-pointer hvr-underline-from-center'
          style={{
            boxShadow: showAbout
              ? "inset 4px 4px 7px #aa2020, inset -4px -4px 7px #fa3030"
              : "4px 4px 7px #aa2020, -4px -4px 7px #fa3030",
            transition: "box-shadow 0.5s ease"
          }}
          onClick={handleAbout}
        >
          <p className='text-white'>{!showAbout ? `About` : `Home`}</p>
        </div>}

        {/* {showAbout && <div className='absolute right-10 top-32 w-[32rem] h-[35rem] bg-[#d16f48] rounded-md'> */}

        {/* </div>} */}

        {/* <iframe className='absolute top-0 hidden md:block  right-0  opacity-30' src='https://my.spline.design/untitled-37d0863044817b89035ca9298940c9e6/' frameborder='0' width='100%' height='100%'></iframe> */}


        <div className='flex flex-col pt-40  '>
          {/* <motion.div initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}>
            <img className='m-auto h-60 z-30' src={photo} alt="" />
          </motion.div> */}

          <iframe className='absolute -top-10 ' src='https://my.spline.design/componentuicopy-b65238cdf8412a9c6cf7e9e682363429/' frameborder='0' width='100%' height='100%'></iframe>



          {!(work || project || resume) && <p className='text-center text-4xl  mb-4 z-30  mt-64 xl:mt-80 text-red-200' style={{ "fontFamily": "Rubik Wet Paint" }} >Abhishek Pal</p>}
          <div className=' w-80 mx-auto'>
            <hr className='w-80 m-auto ' />
            {!showAbout && <motion.div className="" animate={{ x: [0, 298, 0] }} transition={{ ease: 'linear', duration: 100, repeat: Infinity }}><i class="fa-solid fa-circle fa-lg absolute bg-white "></i></motion.div>}
          </div>
          <AnimatePresence>
            <motion.div className='w-80 m-auto' animate={{ opacity: showAbout ? 1 : 0 }} transition={spring} >
              {showAbout && <p className='text-center p-5 text-white' style={{ "box-shadow": "inset 5px 5px 8px #9e1e1e, inset -5px -5px 8px #ff3232" }}>Experienced Full Stack Developer and Team Lead with a demonstrated history of working in remote environments. Proficient in a range
                of programming languages including C, C++, JavaScript, Python, HTML, CSS, React, and Tailwind. </p>}
            </motion.div>
          </AnimatePresence>

          <hr className='w-80 m-auto' />
        </div>

        {!(work || project || resume) && <div className='flex gap-8 justify-center mt-4 pb-10'>
          <a className='z-30' href="https://www.facebook.com/" target='_blank'><i class="fa-brands fa-facebook fa-2xl"></i></a>
          <a className='z-30' href="https://www.linkedin.com/in/abhishek-pal-057309272" target='_blank'><i class="fa-brands fa-linkedin fa-2xl"></i></a>
          <a className='z-30' href="https://www.instagram.com/_abhisk_/?igshid=OGQ5ZDc2ODk2ZA%3D%3D" target='_blank'><i class="fa-brands fa-instagram fa-2xl"></i></a>
          <a className='z-30' href="https://github.com/Tissu07" target='_blank'><i class="fa-brands fa-github fa-2xl"></i></a>
        </div>}
      </div>



      {/* {!showAbout && <div className='hidden lg:block absolute top-10 left-10  overflow-hidden h-[40rem] opacity-20 hover:opacity-80 backdrop-blur-md' > */}
      {!showAbout && <div className='hidden lg:block absolute top-10 left-2 xl:left-10  overflow-hidden h-[90%] opacity-20 hover:opacity-80 backdrop-blur-md' >

        <motion.div className="z-10 rounded-lg  h-screen w-full" animate={{ y: [0, -2000, 0] }} transition={{ type: "ease", duration: 200, repeat: Infinity }}>
          <ProfileSide heading="Bangla Sahib" description="Experiencing serenity at Delhi's Bangla Sahib Gurudwara marked a significant post-college visit. Immersed in its peaceful ambiance, I found inspiration and a renewed sense of purpose. The positive vibes encountered during this visit fuel my drive to move forward with enthusiasm." photo={side1} />
          <ProfileSide photo={side2} description="Capturing the essence of anticipation on my first day of college, a photo encapsulates my excitement. Bathed in sunlight, my shadow symbolizes the dawn of a new life chapter, sparking the beginning of countless possibilities and personal growth." heading="Starting Collage life" />
          <ProfileSide photo={side3} heading="Attend First Hackathon" description="Participating in a college hackathon proved transformative. Though initially an attendee, the experience became a catalyst for my exploration into cutting-edge technologies. The event ignited a passion for automotive tech, steering me towards a journey of continuous learning and innovation." />
          <ProfileSide photo={side4} heading="Drawing" description="An avid artist, I find immense joy in the fluidity of pen on paper. The act of drawing, where lines weave into meaning, is a cherished pursuit. It's a passion that not only brings me joy but also serves as a creative outlet for self-expression." />
          <ProfileSide photo={side5} heading="Riding Bike" description="Embarking on a carefree bike ride with a friend is a cherished memory. Those moments of cruising on two wheels represent pure joy and relaxation, encapsulating the essence of life's simple pleasures and the camaraderie that comes with shared adventures." />
          <ProfileSide photo={side6} heading="Night out" description="Venturing into the thrill of a night out horror with friends, we explored the entire university campus. Amidst the eerie atmosphere, laughter and shared frights created lasting memories. It was an unforgettable experience that brought us closer and added a unique twist to our university journey." />
          <ProfileSide photo={side7} heading="Painting" description="Finding inspiration in the artistry of others, I take delight in exploring paintings by fellow artists. Visiting painting shops has become a cherished ritual, allowing me to marvel at the beauty created by talented individuals. It's a source of continuous admiration and a reminder of the boundless creativity within the art world." />
          <ProfileSide photo={side8} heading="Attending Parties" description="Attending birthday parties is a delightful journey back to childhood, filled with the joy of cakes, pastries, sweets, and balloons. These celebrations not only evoke cherished memories but also offer a chance to relive the carefree moments of youth. Each party becomes a joyous escape and a reminder of the simple pleasures in life." />
          <ProfileSide photo={side9} heading="Foodie" description="Embracing a love for healthy, vegetable-rich foods is a cornerstone of my foodie journey. While I prioritize wellness, there's also joy in occasionally treating myself to delightful fast food experiences. Momos, with their delicious blend of flavors, stand out as a special indulgence, adding a positive and flavorful note to my culinary adventures." />
        </motion.div>
      </div>}

      {!(project || resume || blog || work || showAbout) && <div className='hidden lg:block w-80 absolute bottom-0 -left-20'>
        <Interact />
      </div>}

      <div className='hidden lg:block absolute bottom-0 left-14 text-white '>
        {!showAbout && <p>Sero</p>}
      </div>


      {showAbout && <div className='hidden lg:block text-white  absolute top-40 lg:right-10 xl:right-20 lg:w-[18rem] xl:w-[23rem] p-2' style={{ "box-shadow": "inset 5px 5px 8px #9e1e1e, inset -5px -5px 8px #ff3232", "fontFamily": "Inclusive Sans" }}>
        <p className='xl:mb-8 text-2xl w-40'>Education</p>
        <ul className="list-disc pl-5 text-base" style={{ "font-weight": "100" }}>
          <li className='py-1'><span className='text-black'>Bachelor of Technology</span> - BTech, Computer Engineering
            Sharda University • Greater Noida • April 2025 • 8.4</li>
          <li className='py-1'><span className='text-black'>Higher Education</span>- 11 & 12, PCM Oxford public school  •Ranchi •June 2021 • 85%</li>
          <li className='py-1'><span className='text-black'>Secondary Education</span>- 10, DAV public school  •Ranchi •Mar 2019 • 90%</li>
        </ul>
      </div>}

      {showAbout && <div className='text-white hidden lg:block absolute lg:top-[27rem] xl:top-[26rem] lg:right-10 xl:right-20 lg:w-[18rem] xl:w-[23rem] mt-14 p-2 ' style={{ "box-shadow": "inset 5px 5px 8px #9e1e1e, inset -5px -5px 8px #ff3232", "fontFamily": "Inclusive Sans" }}>
        <p className='xl:mb-8 text-2xl'>Experience</p>
        <ul className="list-disc pl-5 text-base">
          <li className='py-1'><span className='text-black'>"Full stack developer</span>-
            Hinafi Technology June 2023 - September 2023, Delhi (Intern)</li>
          {/* <li className='py-1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora, quaerat!</li>
          <li className='py-1'>SLorem ipsum dolor sit amet consectetur adipisicing elit. Tempora, quaerat!</li> */}
        </ul>
      </div>}

      {showAbout && <div className='hidden lg:block'>
        <p className='pl-6 text-lg text-white absolute top-52'>Me</p>
        <motion.div className=' hidden lg:block rounded-full absolute top-60' animate={{ scale: one ? 1 : 0.8 }} whileHover={{ scale: 1, duration: 0.05 }} onClick={handleOne}>

          <img className='rounded-full h-16' src={photo} alt="" />
          {/* {one && <Hero className='absolute -top-16' image={photo} />} */}
          {/* <p className='pl-3'>Skills</p> */}
        </motion.div>
        <motion.div className='hidden lg:block rounded-full absolute top-60 ' animate={{ y: [0, 100], scale: two ? 1 : 0.8 }} whileHover={{ scale: 1, duration: 0.1 }} onClick={handleTwo}>
          <img className='rounded-full h-16' src={photo} alt="" />
          {/* {two && <Hero image={rain}  />} */}
        </motion.div>
        <motion.div className='hidden lg:block rounded-full absolute top-60' animate={{ y: [0, 200], scale: three ? 1 : 0.8 }} whileHover={{ scale: 1, duration: 0.1 }} onClick={handleThree}>
          <img className='rounded-full h-16' src={photo} alt="" />
          {/* {three && <Hero image={rain}  />} */}
        </motion.div>

      </div>}

      {one && <div className='hidden lg:block absolute left-16 top-64 text-white'>
        <p className='rounded-lg w-10 h-6' style={{ "box-shadow": "inset 5px 5px 8px #9e1e1e, inset -5px -5px 8px #ff3232" }}></p>
      </div>}
      {two && <div className='hidden lg:block absolute left-16 top-[22.5rem] text-white'>
        <p className='rounded-lg w-10 h-6' style={{ "box-shadow": "inset 5px 5px 8px #9e1e1e, inset -5px -5px 8px #ff3232" }}></p>
      </div>}
      {three && <div className='hidden lg:block absolute left-16 top-[28.8rem] text-white'>
        <p className='rounded-lg w-10 h-6' style={{ "box-shadow": "inset 5px 5px 8px #9e1e1e, inset -5px -5px 8px #ff3232" }}></p>
      </div>}


      {showAbout && <div>
        {one && <Hero image={indrivenew} desc={'In the prestigious "Incode" hackathon hosted by Indrive, I proudly joined the ranks of 2873 participants. Thriving through fierce competition, my focus on safety secured my spot in the ideation round, where only 200 innovators advanced. Though victory eluded me, the experience enriched my knowledge. I passionately delved into the safety domain, and my idea stood out. As a testament to this journey, my final prototype video is now online—capturing the essence of innovation, resilience, and the pursuit of a safer future. Grateful for the opportunity, I continue to learn and grow from this transformative hackathon experience. Explore my journey here.'} linkurl="https://youtu.be/soNyF7W2bY0?si=PCw-s1KpKsnbz16H" />}
        {two && <Hero image={rain} desc="Coming Soon..." />}
        {three && <Hero image={rain} desc="Coming Soon..." />}
      </div>}



      {/* {!showAbout && <div className='hidden lg:block absolute  w-72 bottom-20 right-20 text-white text-right'>
        <p dangerouslySetInnerHTML={{ __html: text }}></p>
      </div>} */}


      {(!showAbout) && <motion.div className='hidden lg:block  top-72 right-0 z-30 fixed ' initial={{ x: 100 }}
        animate={{ x: 0 }}
        transition={{ duration: 0.2 }} style={{ color: (resume || project) ? 'white' : 'black', }}>
        <div className='flex flex-col w-12 relative '>
          {(resume || project || work) && <motion.button className='py-3 w-10 cursor-pointer relative' onClick={handleHome} whileHover={{ scale: 1.2, transition: { duration: 0.2 } }}>
            <i class="fa-solid fa-house fa-xl"></i>
            <div className='absolute top-3 right-14 text-white '>Home</div>
          </motion.button>}
          {(!work) && <motion.button className='py-3 w-8 relative' whileHover={{ scale: 1.2, transition: { duration: 0.2 } }} onClick={handleWork}  >
            <i class="fa-solid fa-mug-saucer fa-lg px-2"></i>
            <div className='absolute top-3 right-14 text-white '>Work</div>
          </motion.button>}
          {(!project) && <motion.button className='py-3 w-10 relative' onClick={handleProject} whileHover={{ scale: 1.2, transition: { duration: 0.2 } }}>
            <i class="fa-solid fa-briefcase fa-xl px-2"></i>
            <div className='absolute top-3 right-14 text-white'>Projects</div>
          </motion.button>}
          <motion.button className='py-3 w-10 cursor-pointer relative' onClick={handleBlog} whileHover={{ scale: 1.2, transition: { duration: 0.2 } }}>
            <a href="https://abhistyle01.blogspot.com/" target='_blank'><i class="fa-brands fa-blogger fa-xl px-2"></i></a>
            <div className='absolute top-3 right-14 text-white'>Blogs</div>
          </motion.button>
          {/* {(!blog) && <motion.button className='py-3 w-10 cursor-pointer relative hidden' onClick={handleBlog} whileHover={{ scale: 1.2, transition: { duration: 0.2 } }}>
            <a href="https://abhistyle01.blogspot.com/" target='_blank'><i class="fa-brands fa-blogger fa-xl px-2"></i></a>
            <div className='absolute top-3 right-14 text-white'>Blogs</div>
          </motion.button>} */}
          {(!resume) && <motion.button className='py-3 w-10 cursor-pointer relative' onClick={handleResume} whileHover={{ scale: 1.2, transition: { duration: 0.2 } }}>
            <i class="fa-regular fa-file fa-xl px-2"></i>
            <div className='absolute top-3 right-14 text-white'>Resume</div>
          </motion.button>}

        </div>
      </motion.div>}


      {resume && <div className='absolute top-0 left-0 z-20'> <Resume /></div>}
      {/* {(project||blog||work) && <div className="z-10   bg-opacity-100 backdrop-blur-md p-4 rounded-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-screen w-full" onClick={handleMaster}></div>} */}
      {/* {project && <div className='absolute top-0 w-full'><Projects handleProjectBack={() => handleProjectBack} /></div>} */}
      {project && <div className='absolute top-0 w-full'><Project2 /></div>}
      {work && <div className='absolute top-0 w-full'><Work /></div>}

      {!(work || project || resume) && <div className='hidden lg:block w-40 h-16 bg-red-500 absolute bottom-10 right-0'>

      </div>}



    </>
  )
}

export default Home



// backdrop-blur-md
// https://renuvo-128.webflow.io/