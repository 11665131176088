import './App.css';
import './main.css'
import Home from'./component/Home'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import ProfileSide from './component/ProfileSide';
import Resume from './component/Resume';
import Projects from './component/Projects';
import Hero from './component/Hero';
import Project2 from './component/Project2';
import Work from './component/Work';
import Skill from './component/Skills';



function App() {
  return (
    <Router>

          <div className=''>
            <Routes>
              <Route exact path="/" element={<Home/>} />
              <Route exact path="/profile" element={<ProfileSide />} />
              <Route exact path="/resume" element={<Resume />} />
              <Route exact path="/projects" element={<Projects />} />
              <Route exact path="/project" element={<Project2 />} />
              <Route exact path="/work" element={<Work />} />
              <Route exact path="/skill" element={<Skill/>} />
            </Routes>
          </div>
        </Router>
    
   
  );
}

export default App;
