import React, { useEffect } from 'react'
import car from '../image/car.jpg'
import { FlatTree, color, motion, useScroll, useTransform } from "framer-motion"
import { useRef } from 'react'
import Lenis from '@studio-freight/lenis'
import Project2 from './Project2'

function Projects(props) {

  const{handleProjectBack} = props;

  const targetRef = useRef < HTMLDivElement > (null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    // offset: ["start end","end start" ]
  });


  useEffect(() => {
    const lenis = new Lenis()



    function raf(time) {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)
  })

  const y = useTransform(scrollYProgress, [0, 0.5], [40, -800])
  const x = useTransform(scrollYProgress, [0, 0.1], [0, -50])
  const x1 = useTransform(scrollYProgress, [0.1, 0.2], [0, 100])
  const y1 = useTransform(scrollYProgress, [0, 0.6], [0, -1000])
  const y2 = useTransform(scrollYProgress, [0, 0.4], [0, -400])
  const y3 = useTransform(scrollYProgress, [0, 0.6], [0, -780])
  const scale = useTransform(scrollYProgress, [0, 0.2], [1, 1.5])
  const scale1 = useTransform(scrollYProgress, [0, 0.2], [1, 0])
  const opacity = useTransform(scrollYProgress, [0,0.3, 0.6, 0.65], [0,0.8, 0.3, 0])
  const opacity1 = useTransform(scrollYProgress, [0.15, 0.19], [1, 0])
  const opacity2 = useTransform(scrollYProgress, [0.15, 0.16], [1, 0])
  const opacity3 = useTransform(scrollYProgress, [0.13, 0.14], [1, 0])


  return (

    <div className=' bg-black  overflow-hidden' useRef={targetRef} style={color}>

      <div className='w-20 h-10 bg-white absolute top-10 left-10  rounded-xl cursor-pointer' onClick={handleProjectBack}>
        <p className='text-3xl text-center'>Back</p>
      </div>

      <motion.div className='relative'>

        <motion.div style={{opacity:opacity3}} className='text-8xl text-center pt-24 border-2 border-black text-white'>Projects</motion.div>

        <motion.div style={{ y, scale, opacity:opacity2 }} ><img className='h-[40rem] mx-auto pt-36' src={car} alt="" /></motion.div>

        <div className='flex  mx-auto pl-10 w-[60rem] border-white border-2 '>

          <motion.div style={{ y: y1, x }} ><img className='h-[30rem] mx-auto pt-36' src={car} alt="" /></motion.div>

          <motion.div className="w-1/4" style={{ opacity , y:y2 }}><p className='text-white'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus dolores eos culpa saepe dolore sequi natus, libero reiciendis harum facere esse. Dolore ex, voluptatum debitis a distinctio impedit, at dignissimos facilis soluta possimusquibusdam?</p>
          </motion.div>

          <div className='absolute w-[50%] z-0' >
            <img src={car} alt="" />
          </div>

        </div>

        <div className='flex  mx-auto pl-10 w-[60rem] border-white border-2 relative'>
          <motion.div className="w-1/4" style={{ opacity1 , y:y3 }}><p className='text-white'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus dolores eos culpa saepe dolore sequi natus, libero reiciendis harum facere esse. Dolore ex, voluptatum debitis a distinctio impedit, at dignissimos facilis soluta possimusquibusdam?</p></motion.div>
          <motion.div style={{  y: y1, x: x1 }} ><img className='h-[30rem] mx-auto pt-36' src={car} alt="" /></motion.div>

          <div className='absolute top-40 left-40'>
            <p className='text-white text-9xl '>Thankyou</p>
          </div>
        </div>

       

     


      </motion.div>
    </div>
  )
}

export default Projects


// https://dribbble.com/shots/15508590-Interactive-showcase-website-Webflow