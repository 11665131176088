import React from 'react'
import img from '../image/ecommerce.png'
import img3 from '../image/ecommerce2.png'
import img2 from '../image/img2.jpg'
import decor from '../image/decor.jpg'
import project from '../image/project2.png'
import showcase from '../image/Showcase1.jpeg'
import showcase2 from '../image/Showcase2.jpeg'
import showcase4 from '../image/Showcase4.jpeg'
import indrive from '../image/indrive.png'
import final from '../image/final1.jpeg'
import catalog from '../image/catalog.png'
import shop from '../image/shop.png'
import healthpage from '../image/healthpage.jpeg'

function Project2() {
    return (
        <div className='hidden lg:block bg-[#292929] pt-10'>

            <div>
                <p className='text-4xl font-bold mx-20 text-white'>Project</p>
            </div>

            <div className='hidden lg:block  mx-52 lg:py-0 xl:py-10 '>
                <div className=' '>
                    <div className=' relative lg:h-[35rem] xl:h-[40rem] '>
                        <img className='lg:w-[32rem] xl:w-[42rem] absolute lg:top-14 xl:top-16 z-40 ' src={img} alt="" />
                        {/* <img className='w-[60%] absolute right-0' src={img} alt="" /> */}
                        <div className='lg:w-[25rem] lg:h-[22rem] xl:w-[32rem] xl:h-[27rem] bg-black absolute right-0 z-0 my-2 text-white text-5xl font-bold lg:px-10 xl:px-2 '>
                            ECOMMERCE
                        </div>
                        <img className='lg:w-60 lg:h-60 xl:w-80 xl:h-80 absolute lg:bottom-40 xl:bottom-44 right-10 z-50 ' src={img3} alt="" />
                        <img className='w-16 h-16 xl:w-40 xl:h-40 absolute top-16 xl:-top-10 lg:right-6 xl:right-10 z-10' src={decor} alt="" />

                        <div className='absolute bottom-8 w-[30rem] left-10'>
                            <p className='text-xl py-2 text-white font-bold'>E-commerce</p>
                            <p className='text-white text-justify'>Discover a seamless online shopping experience with our MERN-based e-commerce platform. We've meticulously crafted every detail, from an intuitive user interface to a robust backend, ensuring a secure and efficient shopping journey. Explore a curated selection of products with ease, thanks to our user-friendly navigation and responsive design. </p>
                        </div>

                        <div className='hidden xl:block absolute bottom-8 w-80 right-10'>
                            <p className='text-xl font-bold text-white'>Unleash Fashion at Your Fingertips – Where Trend Meets Convenience!.</p>
                            <p className='text-sm py-4 text-white'>shop now...</p>
                        </div>
                    </div>
                </div>

                <div className='flex justify-center xl:justify-normal relative lg:mt-10 xl:mt-20'>
                    <div className=''>
                        <img className='lg:w-80 lg:h-60 xl:w-[30rem] object-scale-down ' src={project} alt="" />
                    </div>
                    <div className='px-4 xl:px-10 w-1/2 lg:py-8 xl:py-0 text-justify z-10'>
                        <p className='text-white'>Step into a sleek digital realm with our cutting-edge login experience. Our Windows-inspired interface, crafted with finesse using Tailwind CSS in React, merges style with seamless functionality. Unlock a world of user-friendly design and dynamic aesthetics, as you navigate through an interface reminiscent of the iconic Windows login.Elevate your login journey with our fusion of React's power and Tailwind's elegance, where aesthetics meet efficiency. Embrace the future of login interfaces – stylish, intuitive, and uniquely yours. Login, and let the experience unfold.</p>
                    </div>
                    <div className='w-20 h-20 absolute -bottom-4 -right-4 xl:-right-0 bg-black z-0'>
                        a
                    </div>
                </div>

                <div className='flex lg:mt-20 xl:mt-40'>
                    <div className='lg:mx-1 xl:mx-2 relative'>
                        <img className=' object-scale-down w-[35rem]' src={showcase} alt="" />
                        <div className='border-2 bg-white border-white absolute top-5 left-4 h-[90%] w-[90%] opacity-0 hover:opacity-30 '  >
                            <p className='text-5xl absolute bottom-1/2 left-20 text-black'>Health</p>
                        </div>
                    </div>
                    <div className='lg:mx-1 xl:mx-2 relative'>
                        <img className=' object-scale-down w-[35rem]' src={showcase2} alt="" />
                        <div className='border-2 bg-white border-white absolute top-5 left-4 h-[90%] w-[90%] opacity-0 hover:opacity-30 '  >
                            <p className='text-5xl absolute bottom-1/2 left-24  text-black'>Hotel</p>
                        </div>
                    </div>
                    <div className='lg:mx-1 xl:mx-2 relative'>
                        <img className='object-scale-down w-[35rem]' src={showcase4} alt="" />
                        <div className='border-2 bg-white border-white absolute top-5 left-4 h-[90%] w-[90%] opacity-0 hover:opacity-30 '  >
                            <p className='text-5xl absolute bottom-1/2 left-24 text-black'>Invest</p>
                        </div>
                    </div>
                </div>

                <div className='mt-24 relative h-96 '>
                    <div className='bg-black absolute h-80 w-80'>
                        a
                    </div>
                    <div className='border-2 border-white h-96 absolute top-10 left-10 w-[95%]'>
                        <p className='w-72 text-white px-4 py-10 text-justify'>"Revolutionizing road safety with 'Safety in Indrive,' our hackathon project advances to the prototype round. Focused on real-time hazard detection and driver assistance, we're pioneering in-vehicle safety for a secure driving experience. Join us in creating a safer future on the roads."</p>
                        <img className='xl:w-[30rem] lg:w-72 lg:h-60 object-scale-down absolute xl:right-10 lg:right-4 lg:top-28 xl:top-16' src={indrive} alt="" />
                    </div>
                </div>


                <div className=' my-20 mt-40'>
                    <div className=' relative lg:h-[30rem] xl:h-[40rem]'>

                        <div className='lg:w-72  xl:w-[35rem] mx-6 xl:mx-10 xl:my-8 '>
                            <p className='xl:my-0 xl:text-lg text-white py-10 p-5'>I developed a dynamic e-commerce website with an intuitive homepage, seamless navigation, and detailed product pages for a user-friendly experience. Integrated payment gateways and secure user accounts enhance functionality. Employing responsive design and a tech stack including HTML, CSS, and JavaScript, the website ensures adaptability across device <span className="hidden xl:block py-5">Overcoming challenges with innovative solutions, the project demonstrates my commitment to impactful digital experiences, reflecting technical proficiency and dedication to user satisfaction.</span></p>
                        </div>
                        {/* <img className='w-[60%] absolute right-0' src={img} alt="" /> */}
                        <div className='lg:w-full  xl:w-full  bg-black absolute left-0 bottom-0 z-0 text-white  font-bold px-10 xl:px-3 '>
                            <div className='lg:py-2 xl:py-4'>
                                <div className='flex lg:py-0 xl:py-4 pl-4'>
                                    <p className='text-xl '>Shop</p>
                                    <p className='text-xl lg:px-24 xl:px-64'>Catalog</p>
                                </div>
                                <div className='flex'>
                                    <img className='w-32 lg:w-36 xl:w-72 object-scale-down' src={shop} alt="" />
                                    <img className='w-32 lg:w-40 px-2 xl:w-80 object-scale-down' src={catalog} alt="" />
                                </div>
                            </div>
                        </div>

                        <img className='lg:w-[40] lg:h-[100%]  absolute lg:bottom-0 xl:bottom-0  right-0 z-50 ' src={final} alt="" />
                    </div>
                </div>
            </div>
            <div className='bg-black w-full h-40'>
                
            </div>
        </div>

    )
}

export default Project2
