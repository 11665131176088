import React from 'react'
import img from '../image/img2.jpg'
import avatar from '../image/avatar.png'
import img2 from '../image/ecommerce.png'
import idea from '../image/ideation.png'
import prototype1 from '../image/prototype2.jpg'
import prototype2 from '../image/prototype3.png'
import prototype3 from '../image/prototype5.png'
import prototype4 from '../image/prototype1.jpg'
import prototype5 from '../image/prototype4.png'
import prototype6 from '../image/prototype6.png'
import prototype7 from '../image/Development.png'
import development1 from '../image/development1.png'
import development2 from '../image/development2.png'
import development3 from '../image/development3.png'
import concept from '../image/concept.png'
import final1 from '../image/final1.jpeg'
import portfolio from '../image/Portfolio.png'
function Work() {
    return (
        <div className='hidden lg:block bg-white'>
            <div className=' h-screen relative bg-[#ffffff]'>
                <div className=''>
                    <img className='h-screen lg:w-[40%] xl:w-[40%] opacity-80' src={portfolio} alt="" />
                </div>
                <div className='absolute lg:top-[35%] lg:left-[40%] xl:left-[36%] '>
                    <p className='text-8xl font-bold'>Portfolio</p>
                    <p className='text-5xl font-bold text-center'>Abhishek Pal</p>
                    <p className='text-2xl text-center'>FAVOURITE PROJECTS TILL 2023</p>
                </div>
            </div>

            <div className='relative my-5 flex '>

                <div className='bg-[#cc8f8f] h-screen w-1/2 lg:w-[40%] xl:w-[40%] '>
                    <div className='px-10 xl:px-28 py-24'>
                        <div>
                            <p className='text-7xl font-bold'>Hello</p>
                            <p className='text-4xl font-bold'>I'm Abhishek.</p>
                        </div>
                        <div className=' w-72 h-56 my-8'>
                            <img className='object-scale-down w-80 mx-auto ' src={avatar} alt="" />
                        </div>
                        <div>
                            <p className='text-xl font-bold'>Here's how i came here...</p>
                            <p className='py-2 text-sm'>Driven by a passion for innovation, I've crafted compelling front-end projects that seamlessly blend aesthetics with functionality. <span className='hidden xl:block'>Through a mastery of HTML, CSS, and JavaScript, I bring visions to life. Explore my portfolio for a glimpse into my creative approach to web development</span></p>
                        </div>
                    </div>
                </div>

                <div className='  pt-20 pl-16 xl:pl-36  '>

                    <div className=' mt-2 py-4'>
                        <div className='text-xl font-bold'>Interests</div>
                        <div className='flex my-2'>
                            <div className=' w-40 '>
                                <div className='flex gap-2'>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-device-gamepad-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M12 5h3.5a5 5 0 0 1 0 10h-5.5l-4.015 4.227a2.3 2.3 0 0 1 -3.923 -2.035l1.634 -8.173a5 5 0 0 1 4.904 -4.019h3.4z"></path>
                                            <path d="M14 15l4.07 4.284a2.3 2.3 0 0 0 3.925 -2.023l-1.6 -8.232"></path>
                                            <path d="M8 9v2"></path>
                                            <path d="M7 10h2"></path>
                                            <path d="M14 10h2"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        Gaming
                                    </div>
                                </div>
                                <div className='flex gap-2'>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-code-circle" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M10 14l-2 -2l2 -2"></path>
                                            <path d="M14 10l2 2l-2 2"></path>
                                            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        Coding
                                    </div>
                                </div>
                                <div className='flex gap-2'>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-palette" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M12 21a9 9 0 0 1 0 -18c4.97 0 9 3.582 9 8c0 1.06 -.474 2.078 -1.318 2.828c-.844 .75 -1.989 1.172 -3.182 1.172h-2.5a2 2 0 0 0 -1 3.75a1.3 1.3 0 0 1 -1 2.25"></path>
                                            <path d="M8.5 10.5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                                            <path d="M12.5 7.5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                                            <path d="M16.5 10.5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        Drawing
                                    </div>
                                </div>

                            </div>




                            <div className=' w-40'>

                                <div className='flex gap-2'>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-run" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M13 4m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                                            <path d="M4 17l5 1l.75 -1.5"></path>
                                            <path d="M15 21l0 -4l-4 -3l1 -6"></path>
                                            <path d="M7 12l0 -3l5 -1l3 3l3 1"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        Running
                                    </div>
                                </div>
                                <div className='flex gap-2'>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-backpack" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M5 18v-6a6 6 0 0 1 6 -6h2a6 6 0 0 1 6 6v6a3 3 0 0 1 -3 3h-8a3 3 0 0 1 -3 -3z"></path>
                                            <path d="M10 6v-1a2 2 0 1 1 4 0v1"></path>
                                            <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4"></path>
                                            <path d="M11 10h2"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        Travelling
                                    </div>
                                </div>
                            </div>


                            <div className=' w-40'>
                                <div className='flex gap-2'>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-augmented-reality-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M10 21h-2a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v3.5"></path>
                                            <path d="M17 17l-4 -2.5l4 -2.5l4 2.5v4.5l-4 2.5z"></path>
                                            <path d="M13 14.5v4.5l4 2.5"></path>
                                            <path d="M17 17l4 -2.5"></path>
                                            <path d="M11 4h2"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        Technologies
                                    </div>
                                </div>
                                <div className='flex gap-2'>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-whirl" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M14 12a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z"></path>
                                            <path d="M12 21c-3.314 0 -6 -2.462 -6 -5.5s2.686 -5.5 6 -5.5"></path>
                                            <path d="M21 12c0 3.314 -2.462 6 -5.5 6s-5.5 -2.686 -5.5 -6"></path>
                                            <path d="M12 14c3.314 0 6 -2.462 6 -5.5s-2.686 -5.5 -6 -5.5"></path>
                                            <path d="M14 12c0 -3.314 -2.462 -6 -5.5 -6s-5.5 2.686 -5.5 6"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        Space
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>





                    <div className=' mt-2 py-4'>
                        <div className='text-xl font-bold'>Software Skills</div>
                        <div className='flex my-2'>
                            <div className=' w-40'>
                                <div className='flex gap-2'>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-react" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M6.306 8.711c-2.602 .723 -4.306 1.926 -4.306 3.289c0 2.21 4.477 4 10 4c.773 0 1.526 -.035 2.248 -.102"></path>
                                            <path d="M17.692 15.289c2.603 -.722 4.308 -1.926 4.308 -3.289c0 -2.21 -4.477 -4 -10 -4c-.773 0 -1.526 .035 -2.25 .102"></path>
                                            <path d="M6.305 15.287c-.676 2.615 -.485 4.693 .695 5.373c1.913 1.105 5.703 -1.877 8.464 -6.66c.387 -.67 .733 -1.339 1.036 -2"></path>
                                            <path d="M17.694 8.716c.677 -2.616 .487 -4.696 -.694 -5.376c-1.913 -1.105 -5.703 1.877 -8.464 6.66c-.387 .67 -.733 1.34 -1.037 2"></path>
                                            <path d="M12 5.424c-1.925 -1.892 -3.82 -2.766 -5 -2.084c-1.913 1.104 -1.226 5.877 1.536 10.66c.386 .67 .793 1.304 1.212 1.896"></path>
                                            <path d="M12 18.574c1.926 1.893 3.821 2.768 5 2.086c1.913 -1.104 1.226 -5.877 -1.536 -10.66c-.375 -.65 -.78 -1.283 -1.212 -1.897"></path>
                                            <path d="M11.5 12.866a1 1 0 1 0 1 -1.732a1 1 0 0 0 -1 1.732z"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        React
                                    </div>
                                </div>
                                <div className='flex gap-2'>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-nodejs" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M9 9v8.044a2 2 0 0 1 -2.996 1.734l-1.568 -.9a3 3 0 0 1 -1.436 -2.561v-6.635a3 3 0 0 1 1.436 -2.56l6 -3.667a3 3 0 0 1 3.128 0l6 3.667a3 3 0 0 1 1.436 2.561v6.634a3 3 0 0 1 -1.436 2.56l-6 3.667a3 3 0 0 1 -3.128 0"></path>
                                            <path d="M17 9h-3.5a1.5 1.5 0 0 0 0 3h2a1.5 1.5 0 0 1 0 3h-3.5"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        Node js
                                    </div>
                                </div>
                                <div className='flex gap-2'>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-tailwind" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M11.667 6c-2.49 0 -4.044 1.222 -4.667 3.667c.933 -1.223 2.023 -1.68 3.267 -1.375c.71 .174 1.217 .68 1.778 1.24c.916 .912 2 1.968 4.288 1.968c2.49 0 4.044 -1.222 4.667 -3.667c-.933 1.223 -2.023 1.68 -3.267 1.375c-.71 -.174 -1.217 -.68 -1.778 -1.24c-.916 -.912 -1.975 -1.968 -4.288 -1.968zm-4 6.5c-2.49 0 -4.044 1.222 -4.667 3.667c.933 -1.223 2.023 -1.68 3.267 -1.375c.71 .174 1.217 .68 1.778 1.24c.916 .912 1.975 1.968 4.288 1.968c2.49 0 4.044 -1.222 4.667 -3.667c-.933 1.223 -2.023 1.68 -3.267 1.375c-.71 -.174 -1.217 -.68 -1.778 -1.24c-.916 -.912 -1.975 -1.968 -4.288 -1.968z"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        Tailwind
                                    </div>
                                </div>

                            </div>
                            <div className=' w-40'>
                                <div className='flex gap-2'>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-cpp" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M18 12h4"></path>
                                            <path d="M20 10v4"></path>
                                            <path d="M11 12h4"></path>
                                            <path d="M13 10v4"></path>
                                            <path d="M9 9a3 3 0 0 0 -3 -3h-.5a3.5 3.5 0 0 0 -3.5 3.5v5a3.5 3.5 0 0 0 3.5 3.5h.5a3 3 0 0 0 3 -3"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        language
                                    </div>
                                </div>
                                <div className='flex gap-2'>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-python" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M12 9h-7a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h3"></path>
                                            <path d="M12 15h7a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-3"></path>
                                            <path d="M8 9v-4a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v5a2 2 0 0 1 -2 2h-4a2 2 0 0 0 -2 2v5a2 2 0 0 0 2 2h4a2 2 0 0 0 2 -2v-4"></path>
                                            <path d="M11 6l0 .01"></path>
                                            <path d="M13 18l0 .01"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        Python
                                    </div>
                                </div>
                                <div className='flex gap-2'>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-javascript" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M20 4l-2 14.5l-6 2l-6 -2l-2 -14.5z"></path>
                                            <path d="M7.5 8h3v8l-2 -1"></path>
                                            <path d="M16.5 8h-2.5a.5 .5 0 0 0 -.5 .5v3a.5 .5 0 0 0 .5 .5h1.423a.5 .5 0 0 1 .495 .57l-.418 2.93l-2 .5"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        Javascript
                                    </div>
                                </div>

                            </div>
                            <div className=' w-40'>
                                <div className='flex gap-2'>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-html5" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M20 4l-2 14.5l-6 2l-6 -2l-2 -14.5z"></path>
                                            <path d="M15.5 8h-7l.5 4h6l-.5 3.5l-2.5 .75l-2.5 -.75l-.1 -.5"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        Html
                                    </div>
                                </div>
                                <div className='flex gap-2'>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-css3" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M20 4l-2 14.5l-6 2l-6 -2l-2 -14.5z"></path>
                                            <path d="M8.5 8h7l-4.5 4h4l-.5 3.5l-2.5 .75l-2.5 -.75l-.1 -.5"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        Css
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className=' mt-5 py-4'>
                        <div className='text-xl font-bold'>Contact</div>
                        <div className=' my-2 text-sm'>
                            <p>Abhishek</p>
                            <p className='text-sm'>LinkedIn: <a href='https://www.linkedin.com/in/abhishek-pal-057309272' className='text-blue-600'>https://www.linkedin.com/in/abhishek-pal-057309272</a></p>
                            <p className='text-sm'> Delhi / India</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className=' h-screen relative flex '>

                <div className='bg-[#cc8f8f] h-screen w-1/2 lg:w-[40%] xl:w-[40%] pt-56 text-white'>

                    <p className='text-7xl font-bold text-center'>NOW</p>
                    <p className='text-5xl text-center font-bold'>Cool Shop</p>
                    <p className='text-center text-lg w-80 mx-auto py-2' >E-commerce website Shop online frontend project</p>

                    <div className='mt-20'>
                        <div className='text-xs flex'>
                            <div className='w-1/2  mx-5 '>
                                <p className='text-right'>DURATION</p>
                            </div>
                            <div className='w-1/2'>
                                2 weeks
                            </div>
                        </div>
                        <div className='text-xs flex'>
                            <div className='w-1/2  mx-5 '>
                                <p className='text-right'>YEAR</p>
                            </div>
                            <div className='w-1/2'>
                                2023
                            </div>
                        </div>
                    </div>

                    <div className='mt-16 ml-6'>
                        <p className='text-xs text-center'> SOFTWARE USED</p>
                    </div>

                    <div className='flex gap-4 justify-center ml-6 py-2'>
                        <div className=''>
                            <i class="fa-brands fa-figma"></i>
                        </div>
                        <div className=''>
                            <i class="fa-brands fa-react"></i>
                        </div>
                        <div className=''>
                            <i class="fa-brands fa-node-js"></i>
                        </div>
                        <div className=''>
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-tailwind" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M11.667 6c-2.49 0 -4.044 1.222 -4.667 3.667c.933 -1.223 2.023 -1.68 3.267 -1.375c.71 .174 1.217 .68 1.778 1.24c.916 .912 2 1.968 4.288 1.968c2.49 0 4.044 -1.222 4.667 -3.667c-.933 1.223 -2.023 1.68 -3.267 1.375c-.71 -.174 -1.217 -.68 -1.778 -1.24c-.916 -.912 -1.975 -1.968 -4.288 -1.968zm-4 6.5c-2.49 0 -4.044 1.222 -4.667 3.667c.933 -1.223 2.023 -1.68 3.267 -1.375c.71 .174 1.217 .68 1.778 1.24c.916 .912 1.975 1.968 4.288 1.968c2.49 0 4.044 -1.222 4.667 -3.667c-.933 1.223 -2.023 1.68 -3.267 1.375c-.71 -.174 -1.217 -.68 -1.778 -1.24c-.916 -.912 -1.975 -1.968 -4.288 -1.968z"></path>
                            </svg>
                        </div>
                    </div>


                </div>

                <div className='lg:w-[60%] xl:w-[60%] bg-[#D17474] h-screen'>
                    <img className='object-scale-down w-[80%] mx-auto py-48 ' src={img2} alt="" />
                </div>

            </div>



            <div className=' h-screen relative flex py-5'>

                <div className='bg-[#cc8f8f]  h-screen w-1/2 lg:w-[40%] xl:w-[40%] pt-16 '>

                    <div className=' w-72  mx-auto'>
                        <img className=' w-72  object-scale-down' src={idea} alt="" />
                        <p className='text-[0.65rem] text-white  pl-6' >THE IDEA: FOCUSING ON THE MOMENT</p>
                    </div>

                    <div className='w-80 mt-40 ml-20 '>
                        <p className='text-2xl text-white mb-10 font-bold '>Concept</p>
                        <p className='text-xs text-white '>In the concept phase, I blend creativity with strategy, exploring ideas and refining them into a cohesive vision. This stage lays the groundwork for innovative solutions that not only meet objectives but elevate the user experience to new heights. Welcome to the genesis of compelling design. </p>
                    </div>


                </div>

                <div className='lg:w-[60%] xl:w-[60%] bg-[#D17474] h-screen'>
                    <img className='object-scale-down w-[75%] mx-auto py-6 ' src={concept} alt="" />
                </div>

            </div>


            <div className=' h-screen relative flex py-5 mt-5'>

                <div className='bg-[#cc8f8f] h-screen w-1/2 lg:w-[40%] xl:w-[40%] pt-10 '>
                    <div className='w-80 mt-28 mx-auto '>
                        <p className='text-2xl text-white mb-10 font-bold'>Prototype</p>
                        <p className='text-xs my-2 text-white'>Witness the blueprint come alive. Explore meticulously crafted layouts and detailed design drawings. This phase transforms ideas into interactive prototypes, providing a tangible preview of the final product. Dive into the dynamic evolution of user experience and functionality.  </p>
                    </div>

                    <div className='  w-80 mx-auto mt-36'>
                        <img className='h-60 w-80  drop-shadow-2xl' src={prototype7} alt="" />
                        {/* <p className='text-xs ' >THE IDEA: FOCUSING ON THE MOMENT</p> */}
                    </div>



                </div>

                <div className='lg:w-[60%] xl:w-[60%] bg-[#D17474] h-screen'>
                    <div className='flex w-[90%] mx-auto justify-center mt-10  gap-6 pl-4 pr-4 '>
                        <div className=''>
                            <img className='w-48 py-2 ' src={prototype1} alt="" />
                        </div>
                        <div>
                            <img className='w-40 py-2' src={prototype2} alt="" />
                        </div>
                        <div>
                            <img className='w-48 py-1' src={prototype3} alt="" />
                        </div>
                    </div>

                    <div className='flex w-[90%] mx-auto  mt-10  gap-6 pl-16 pr-4 '>
                        <div className=''>
                            <img className='w-60 py-4 ' src={prototype4} alt="" />
                        </div>
                        <div>
                            <img className='w-44 py-2' src={prototype5} alt="" />
                        </div>
                        <div>
                            <img className='w-48 py-4' src={prototype6} alt="" />
                        </div>
                    </div>
                </div>

            </div>


            <div className=' h-screen relative flex py-5 mt-5'>

                <div className='bg-[#cc8f8f] h-screen w-1/2 lg:w-[40%] xl:w-[40%] pt-10 '>
                    <div className='w-80 mt-16 mx-auto '>
                        <p className='text-2xl text-white mb-10 font-bold'>Development</p>
                        <p className='text-xs my-2 text-white'>Delve into the heart of execution. Explore the React code that powers seamless functionality. Screenshot snippets from Visual Studio Code capture the precision and craftsmanship behind the scenes. Witness the transformation of concepts into a robust, dynamic reality. </p>
                    </div>

                    <div className='  w-80 mx-auto mt-52'>
                        {/* <img className='object-scale-down w-56 mx-auto   ' src={prototype3} alt="" /> */}
                        <img className='object-scale-down w-[60%] mx-auto drop-shadow-2xl' src={development3} alt="" />
                    </div>



                </div>

                <div className='lg:w-[60%] xl:w-[60%] bg-[#D17474] h-screen'>
                    <div>
                        <img className='w-[30rem] xl:w-[70%] mx-auto mt-6' src={development1} alt="" />
                    </div>
                    <div>
                        <img className='w-[30rem] xl:w-[70%] mx-auto mt-6' src={development2} alt="" />
                    </div>
                </div>
            </div>

            <div className=' h-screen relative flex py-5 mt-5'>

                <div className='bg-[#cc8f8f] h-screen w-1/2 lg:w-[40%] xl:w-[40%] pt-10 '>
                    <div className='w-80 mt-16 mx-auto '>
                        <p className='text-2xl text-white mb-10 font-bold'>Final Page</p>
                        <p className='text-xs my-2 text-white'>Journey's end, destination achieved. Behold the completed masterpiece — a culmination of concepts, precision development, and seamless execution. Dive into the screenshot, a visual testament to the dedication and craftsmanship poured into every pixel. Experience the finality of a well-executed vision.  </p>
                    </div>

                    <div className='  w-80 mx-auto mt-20 xl:mt-24'>
                        <img className='object-scale-down w-[60%] mx-auto drop-shadow-2xl  ' src={prototype3} alt="" />
                        {/* <img className='object-scale-down w-60 mx-auto' src={development3} alt="" /> */}
                    </div>



                </div>

                <div className='lg:w-[60%] xl:w-[60%] bg-[#D17474] h-screen '>
                    <img className='object-scale-down lg:w-[28rem] mx-auto pt-4 ' src={final1} alt="" />
                </div>

            </div>

        </div>
    )
}

export default Work
