import React from 'react'
import Download from './Download'


function Resume() {


  return (
    <div className='hidden lg:block w-full bg-black py-4' >
      <div className='absolute top-16 right-56'>
        <Download name="Download"/>
      </div>
      <div className='mx-96 text-white'>
        <div className='py-10'>
          <p className='text-6xl font-semibold'>Abhishek <br /> Pal</p>
          <p className='text-3xl text-red-500'>I'd rather be <s className='text-white'>sleeping</s> coding</p>
          
        </div>
        <div>
          <div className='flex justify-between mb-8'>
            <p className='mr-44 text-red-500 font-semibold'>PROFILE</p>
            <p className='text-sm text-justify'>Experienced Full Stack Developer and Team Lead with a demonstrated history of working in remote environments. Proficient in a range
              of programming languages including C, C++, JavaScript, Python, HTML, CSS, React, and Tailwind.</p>
          </div>
          <div className='flex justify-between mb-8'>
            <p className='mr-48 text-red-500 font-semibold'>SKILLS</p>
            <div className='flex text-sm'>
              <p className='mr-5 '>front end: HTML, CSS, Javascript. Framework: bootstrap, tailwind, react.</p>
              <p className='mr-5 '>Tailwind CSS, Python (Programming Language), C (Programming Language), React.js, JavaScript, HTML,
                Cascading Style Sheets (CSS)</p>
              <p className='mr-5 '>backend: Node.js, express.js, API.
                database: mongoDB, firebase, mysql.</p>
            </div>
          </div>
          <div className='flex justify-between mb-8'>
            <p className='mr-36 pr-3 text-red-500 font-semibold'>TECHNICAL</p>
            <div className='flex text-sm'>
              <p className='mr-10'>Industry Knowledge: Full-Stack Development</p>
              <p className='mr-10'>Team lead
                Hinafri technology</p>
              <p className='mr-10'>Languages: C, C++, python, javascript, html, css</p>
            </div>
          </div>
          <div className='flex justify-between mb-8'>
            <p className='mr-36  text-red-500 font-semibold'>EXPERIENCE</p>
            <div className='text-sm pl-2'>
              <p className='text-justify'>Full stack developer<br />
                Hinafi Technology June 2023 - September 2023, Delhi<br />
                • Developed and maintained a medical counseling website, ensuring a user-friendly and secure platform for patients seeking guidance.<br />
                • Collaborated closely with UI/UX designers to spearhead the implementation of highly responsive front-end interfaces using React,<br />
                resulting in a 40% improvement in overall user experience and a 20% increase in customer satisfaction.<br />
                • Implemented real-time communication features, enabling seamless interaction between patients and counselors through dynamic<br />
                chat functionalities with 100% accuracy.<br />
              </p>
            </div>

          </div>

        </div>
      </div>
    </div>

  )
}

export default Resume
