import React from 'react'
import '../App.css'


function Download(props) {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = '/resume.pdf'; // Replace with the path to your PDF file
        link.download = 'Resume.pdf'; // Replace with the desired download filename
        link.click();
    };
    return (
        <div>
            <button
                onClick={handleDownload}
                className="hvr-sweep-to-right"
            >
               {props.name}
            </button>
        </div>
    )
}

export default Download
